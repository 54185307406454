//电子病例-现病史：起病情况、进展顺序、主要症状、其它运动症状
import { reactive } from "@vue/composition-api";
import {
  ApiOnsetSave,
  ApiProgressionOrderSave,
  ApiMainSymptomsSave,
  ApiOtherMotorSymptomsSave,
} from "@/apis/index";
interface Module {
  basicInformationForm: object;
}
function onsetModuleFun(
  ins: any,
  basicInformationModule: any,
  chiefComplaintModule: any
) {
  //电子病例-现病史-起病情况
  const onsetModule: any = reactive({
    onsetForm: {
      user_id: "",
      trmtchief_id: "",
      trmtprst_indate: "",
      trmtprst_inage: "",
      trmtprst_fstsymp: "",
      trmtprst_fstposition: "",
      trmtprst_issymmetry: "",
      trmtprst_symmetrydate: "",
      trmtprst_note: "",
    },
    partList: ["左上肢", "左下肢", "右上肢", "右下肢", "头面部"],
    saveOnset() {
      //验证基础信息
      if (!basicInformationModule.caseId) {
        ins.$message.warning("请先填写基本信息！");
        return;
      } else {
        onsetModule.onsetForm.user_id = basicInformationModule.caseId;
      }
      //验证主诉
      // if (!chiefComplaintModule.chiefComplaintId) {
      //   ins.$message.warning("请先填写门诊病历！");
      //   return;
      // } else {
      //   onsetModule.onsetForm.trmtchief_id =
      //     chiefComplaintModule.chiefComplaintId;
      // }
      // onsetModule.onsetForm.trmtprst_id = onsetModule.trmtprst_id;
      const param = {
        ...onsetModule.onsetForm,
      };
      ApiOnsetSave(param).then((res) => {
        if (res.success) {
          onsetModule.trmtprst_id = res.data;
          ins.$message.success("保存成功！");
        }
      });
    },
    onsetRadio(property: string, content: string) {
      if (onsetModule.onsetForm[property] == content) {
        onsetModule.onsetForm[property] = "";
      } else {
        onsetModule.onsetForm[property] = content;
      }
    },
  });
  //电子病例-现病史-进展顺序
  const progressionOrderModule: any = reactive({
    progressionOrderForm: {
      user_id: "",
      trmtchief_id: "",
      lu_limb_date: "",
      ld_limb_date: "",
      ru_limb_date: "",
      rd_limb_date: "",
    },
    saveProgressionOrder() {
      //验证基础信息
      if (!basicInformationModule.caseId) {
        ins.$message.warning("请先填写基本信息！");
        return;
      } else {
        progressionOrderModule.progressionOrderForm.user_id =
          basicInformationModule.caseId;
      }
      //验证主诉
      // if (!chiefComplaintModule.chiefComplaintId) {
      //   ins.$message.warning("请先填写门诊病历！");
      //   return;
      // } else {
      //   progressionOrderModule.progressionOrderForm.trmtchief_id =
      //     chiefComplaintModule.chiefComplaintId;
      // }
      // progressionOrderModule.progressionOrderForm.trmtprst_id =
      //   onsetModule.trmtprst_id;
      const param = {
        ...progressionOrderModule.progressionOrderForm,
      };
      ApiProgressionOrderSave(param).then((res) => {
        if (res.success) {
          onsetModule.trmtprst_id = res.data;
          ins.$message.success("保存成功！");
        }
      });
    },
  });
  //电子病例-现病史-主要症状
  const mainSymptomsModule: any = reactive({
    mainSymptomsForm: {
      user_id: "",
      trmtchief_id: "",

      tremor: "",
      tremor_type: "",
      tremor_item: "",
      tremor_items: "",

      tonic: "",
      tonic_type: "",
      tonic_item: "",

      slow_action: "",
      slow_action_type: "",
      slow_action_item: "",
      slow_action_items: "",

      abnormal_gait: "",
      abnormal_gait_type: "",
      abnormal_gait_item: "",
    },
    diseaseStateList: ["有", "无", "未知"],
    //震颤类型列表
    tremorTypeList: ["典型的帕金森病的静止性震颤", "突出的运动性震颤", "其它"],
    //强直类型列表
    tonicTypeList: [
      "肢体的铅管样强直",
      "肢体的折刀样强直",
      "肢体的齿轮样强直",
      "中轴强直",
      "其它",
    ],
    //运动迟缓类型列表
    bradykinesiaTypeList: [
      "典型的帕金森病的运动迟缓",
      "单纯的运动不能（无强直或震颤)",
      "其它",
    ],
    //步态异常类型列表
    abnormalGaitTypeList: [
      "典型的帕金森病的体位障碍和步态异常",
      "宽基底步态或共济失调",
      "无额外帮助可能摔倒",
      "足内翻",
      "起步困难",
      "其它",
    ],
    saveMainSymptoms() {
      //验证基础信息
      if (!basicInformationModule.caseId) {
        ins.$message.warning("请先填写基本信息！");
        return;
      } else {
        mainSymptomsModule.mainSymptomsForm.user_id =
          basicInformationModule.caseId;
      }
      //验证主诉
      // if (!chiefComplaintModule.chiefComplaintId) {
      //   ins.$message.warning("请先填写门诊病历！");
      //   return;
      // } else {
      //   mainSymptomsModule.mainSymptomsForm.trmtchief_id =
      //     chiefComplaintModule.chiefComplaintId;
      // }
      // mainSymptomsModule.mainSymptomsForm.trmtprst_id = onsetModule.trmtprst_id;
      const param = {
        ...mainSymptomsModule.mainSymptomsForm,
      };
      ApiMainSymptomsSave(param).then((res) => {
        if (res.success) {
          onsetModule.trmtprst_id = res.data;
          ins.$message.success("保存成功！");
        }
      });
    },
    mainSymptomsSelectChange(content: string) {
      switch (content) {
        case "震颤": {
          mainSymptomsModule.mainSymptomsForm.tremor_type = "";
          mainSymptomsModule.mainSymptomsForm.tremor_item = "";
          mainSymptomsModule.mainSymptomsForm.tremor_items = "";
          break;
        }
        case "强直": {
          mainSymptomsModule.mainSymptomsForm.tonic_type = "";
          mainSymptomsModule.mainSymptomsForm.tonic_item = "";
          break;
        }
        case "运动迟缓": {
          mainSymptomsModule.mainSymptomsForm.slow_action_type = "";
          mainSymptomsModule.mainSymptomsForm.slow_action_item = "";
          mainSymptomsModule.mainSymptomsForm.slow_action_items = "";

          break;
        }
        case "步态异常": {
          mainSymptomsModule.mainSymptomsForm.abnormal_gait_type = "";
          mainSymptomsModule.mainSymptomsForm.abnormal_gait_item = "";
          break;
        }
      }
    },
  });
  //电子病例-现病史-其它运动症状
  const otherMotorSymptomsModule: any = reactive({
    otherMotorSymptomsForm: {
      user_id: "",
      trmtchief_id: "",

      panic_gait: "",
      panic_gait_item: "",

      lowercase: "",
      lowercase_item: "",

      difficulty_turning: "",
      difficulty_turning_item: "",

      fall: "",
      fall_item: "",

      language_disability: "",
      language_disability_item: "",

      hard_to_swallow: "",
      hard_to_swallow_item: "",
    },
    saveOtherMotorSymptoms() {
      //验证基础信息
      if (!basicInformationModule.caseId) {
        ins.$message.warning("请先填写基本信息！");
        return;
      } else {
        otherMotorSymptomsModule.otherMotorSymptomsForm.user_id =
          basicInformationModule.caseId;
      }
      //验证主诉
      // if (!chiefComplaintModule.chiefComplaintId) {
      //   ins.$message.warning("请先填写门诊病历！");
      //   return;
      // } else {
      //   otherMotorSymptomsModule.otherMotorSymptomsForm.trmtchief_id =
      //     chiefComplaintModule.chiefComplaintId;
      // }
      // otherMotorSymptomsModule.otherMotorSymptomsForm.trmtprst_id =
      //   onsetModule.trmtprst_id;
      const param = {
        ...otherMotorSymptomsModule.otherMotorSymptomsForm,
      };
      ApiOtherMotorSymptomsSave(param).then((res) => {
        if (res.success) {
          onsetModule.trmtprst_id = res.data;
          ins.$message.success("保存成功！");
        }
      });
    },
    otherMotorSymptomsRadio(property: string, content: string) {
      const propertyName = property + "_item";
      otherMotorSymptomsModule.otherMotorSymptomsForm[propertyName] = "";
      if (
        otherMotorSymptomsModule.otherMotorSymptomsForm[property] == content
      ) {
        otherMotorSymptomsModule.otherMotorSymptomsForm[property] = "";
      } else {
        otherMotorSymptomsModule.otherMotorSymptomsForm[property] = content;
      }
    },
  });
  return {
    onsetModule,
    progressionOrderModule,
    mainSymptomsModule,
    otherMotorSymptomsModule,
  };
}
export { onsetModuleFun };
