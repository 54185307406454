











































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import {
  computed,
  defineComponent,
  getCurrentInstance,
  onMounted,
  onUpdated,
  reactive,
  ref,
  toRef,
  toRefs,
  watch,
} from "@vue/composition-api";
import { scrollModuleFun } from "@/views/EleMediRecord/Scale/ts/add/AddScroll";
import { basicInformationModuleFun } from "@/views/EleMediRecord/Scale/ts/add/basicInformation";
import { chiefComplaintModuleFun } from "@/views/EleMediRecord/Scale/ts/add/chiefComplaint";
import { onsetModuleFun } from "@/views/EleMediRecord/Scale/ts/add/presentIllness";
import { symptomDiagnosisModuleFun } from "@/views/EleMediRecord/Scale/ts/add/symptomDiagnosis";
import { pastMedicalHistoryModuleFun } from "@/views/EleMediRecord/Scale/ts/add/pastMedicalHistory";
import { environmentalExposureModuleFun } from "@/views/EleMediRecord/Scale/ts/add/environmentalExposure";
import { pastLivingHabitsModuleFun } from "@/views/EleMediRecord/Scale/ts/add/pastLivingHabits";
import { familyHistoryModuleFun } from "@/views/EleMediRecord/Scale/ts/add/familyHistory";
import { generalMedicationHistoryModuleFun } from "@/views/EleMediRecord/Scale/ts/add/generalMedicationHistory";
import { movementComplicationsModuleFun } from "@/views/EleMediRecord/Scale/ts/add/movementComplications";
import { otherTreatmentHistoryModuleFun } from "@/views/EleMediRecord/Scale/ts/add/otherTreatmentHistory";
import { number } from "echarts";
import { ApiObjectRecordsDelete } from "@/apis";
import { DirectoryEnum } from "@/constants";
import { uploadFile } from "@/utils/alioss";
import * as utils from "@/utils";

export default defineComponent({
  setup() {
    //公用数据
    const { proxy: _this } = getCurrentInstance() as any;
    //滚动模块
    const { scrollModule } = scrollModuleFun(_this);
    //基础信息模块
    const { basicInformationModule } = basicInformationModuleFun(_this);
    //主诉模块
    const { chiefComplaintModule } = chiefComplaintModuleFun(
      _this,
      basicInformationModule
    );
    //起病情况、进展顺序、主要症状、其它运动症状
    const {
      onsetModule,
      progressionOrderModule,
      mainSymptomsModule,
      otherMotorSymptomsModule,
    } = onsetModuleFun(_this, basicInformationModule, chiefComplaintModule);
    //症状诊断、过敏史
    const { symptomDiagnosisModule } = symptomDiagnosisModuleFun(
      _this,
      basicInformationModule,
      chiefComplaintModule
    );
    //既往史-既往疾病史
    const { pastMedicalHistoryModule } = pastMedicalHistoryModuleFun(
      _this,
      basicInformationModule
    );
    //既往史-既往环境接触史
    const { environmentalExposureModule } = environmentalExposureModuleFun(
      _this,
      basicInformationModule
    );
    //既往史-既往生活习惯
    const { pastLivingHabitsModule } = pastLivingHabitsModuleFun(
      _this,
      basicInformationModule
    );
    //家族史
    const { familyHistoryModule } = familyHistoryModuleFun(
      _this,
      basicInformationModule
    );
    //一般用药史
    const { generalMedicationHistoryModule } =
      generalMedicationHistoryModuleFun(_this, basicInformationModule);
    //运动并发症
    const { movementComplicationsModule } = movementComplicationsModuleFun(
      _this,
      basicInformationModule
    );
    //其它治疗史
    const { otherTreatmentHistoryModule } = otherTreatmentHistoryModuleFun(
      _this,
      basicInformationModule
    );
    //公用逻辑

    function init() {
      //对list主体内容元素监听滚动事件
      const contentDom: any = document.getElementById("list");
      contentDom.addEventListener("scroll", scrollModule.handleScroll);
      //初始化基础信息模块-当前所患疾病下拉列表
      basicInformationModule.initCurrentIllnessList();
    }
    const canUpload = ref(false);

    onMounted(init);
    function clickNext(id: string) {
      scrollModule.updateScroll();
      const contentDom: any = document.getElementById("list");
      for (let i = 0; i < scrollModule.moduleList.length; i++) {
        if (scrollModule.moduleList[i].id == id) {
          const nextId = scrollModule.moduleList[i - 1].id;
          const currentModuleEl = document.querySelector(nextId);
          const currentModuleElHeight = scrollModule.moduleList.find(
            (listItem: any) => {
              return listItem.id == nextId;
            }
          ).top;
          if (currentModuleEl.offsetTop > scrollModule.maximumScrollHeight) {
            contentDom.scrollTop = scrollModule.maximumScrollHeight;
          } else {
            contentDom.scrollTop = currentModuleElHeight + 1;
          }
          return;
        }
      }
    }
    function clickPrevious(id: string) {
      scrollModule.updateScroll();
      const contentDom: any = document.getElementById("list");
      for (let i = 0; i < scrollModule.moduleList.length; i++) {
        if (scrollModule.moduleList[i].id == id) {
          const previousId = scrollModule.moduleList[i + 1].id;
          const currentModuleEl = document.querySelector(previousId);
          const currentModuleElHeight = scrollModule.moduleList.find(
            (listItem: any) => {
              return listItem.id == previousId;
            }
          ).top;
          if (currentModuleEl.offsetTop > scrollModule.maximumScrollHeight) {
            contentDom.scrollTop = scrollModule.maximumScrollHeight;
          } else {
            contentDom.scrollTop = currentModuleElHeight + 1;
          }
          return;
        }
      }
    }
    //删除确认对话框模块
    //确认对话框是否显示
    const showConfirmDialog = ref(false);
    //当前点击所属模块索引值
    let currentIndex: null | number = null;
    let currentFun: any = null;
    //点击删除按钮触发的函数
    function clickDelete(name: string, index: number) {
      currentIndex = index;
      switch (name) {
        case "symptomDiagnosis": {
          currentFun = symptomDiagnosisModule.deleteSymptomDiagnosisFormItem;
          break;
        }
        case "pastMedicalHistory": {
          currentFun =
            pastMedicalHistoryModule.deletePastMedicalHistoryFormItem;
          break;
        }
        case "familyHistory": {
          currentFun = familyHistoryModule.deleteFamilyHistoryFormItem;
          break;
        }
        case "generalMedicationHistory": {
          currentFun =
            generalMedicationHistoryModule.deleteGeneralMedicationHistoryFormItem;
          break;
        }
        case "otherTreatmentHistory": {
          currentFun =
            otherTreatmentHistoryModule.deleteOtherTreatmentHistoryFormItem;
          break;
        }
      }
      showConfirmDialog.value = true;
    }
    //关闭确认对话框函数
    function closeConfirmDialog() {
      showConfirmDialog.value = false;
    }
    //确认对话框确定按钮点击触发
    function completeConfirmConfirm() {
      //点击不同模块的删除按钮，点击确认按钮执行不同模块的删除方法
      currentFun(currentIndex);
      showConfirmDialog.value = false;
    }
    return {
      ...toRefs(scrollModule),
      ...toRefs(basicInformationModule),
      ...toRefs(chiefComplaintModule),
      ...toRefs(onsetModule),
      ...toRefs(progressionOrderModule),
      ...toRefs(mainSymptomsModule),
      ...toRefs(otherMotorSymptomsModule),
      ...toRefs(symptomDiagnosisModule),
      ...toRefs(pastMedicalHistoryModule),
      ...toRefs(environmentalExposureModule),
      ...toRefs(pastLivingHabitsModule),
      ...toRefs(familyHistoryModule),
      ...toRefs(generalMedicationHistoryModule),
      ...toRefs(movementComplicationsModule),
      ...toRefs(otherTreatmentHistoryModule),
      clickNext,
      clickPrevious,
      showConfirmDialog,
      closeConfirmDialog,
      completeConfirmConfirm,
      clickDelete,
      canUpload,
    };
  },
});
