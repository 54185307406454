//电子病例：既往生活习惯
import { computed, onMounted, reactive, watch } from "@vue/composition-api";
import { ApiPastLivingHabitsSave } from "@/apis/index";
interface Module {
  basicInformationForm: object;
}
function pastLivingHabitsModuleFun(ins: any, basicInformationModule: any) {
  const pastLivingHabitsModule: any = reactive({
    pastLivingHabitsForm: {
      user_id: null,
      hishabit_id: null,

      //吸烟
      hishabit_issmoke: "", //是否吸烟
      hishabit_smoke01: "", //日均吸烟量
      hishabit_smoke_date01: "", //开始吸烟时间
      hishabit_smoke04: "", //吸烟年限
      hishabit_smoke_date02: "", //戒烟时间
      hishabit_smoke07: "", //戒烟年限
      hishabit_smoke08: "", //有无被动吸烟
      //饮酒
      hishabit_isdrunk: "", // 是否饮酒
      hishabit_drunk01: "", // 平均每日饮酒量
      hishabit_drunk_date01: "", // 开始饮酒时间
      hishabit_drunk04: "", // 饮酒年限
      hishabit_drunk_date02: "", // 戒酒时间
      hishabit_drunk07: "", // 戒酒年限
      //锻炼
      hishabit_isexercise: "", // 是否锻炼
      hishabit_exercise01: "", // 锻炼频度
      hishabit_exercise02: "", // 锻炼方式
      hishabit_exercise03: "", // 每天锻炼时长
      //喝茶
      hishabit_istea: "", //		是否喝茶
      hishabit_tea01: "", //	频度
      hishabit_tea02: "", //浓度
      hishabit_tea03: "", //		开始喝茶年龄
      hishabit_tea04: "", //		停止喝茶年龄
      hishabit_tea05: "", //		喝茶种类
      //咖啡
      hishabit_iscoffee: "", // 是否喝咖啡
      hishabit_coffee01: "", // 咖啡--频度
      hishabit_coffee02: "", // 咖啡--浓度
      hishabit_coffee03: "", // 开始喝咖啡年龄
      hishabit_coffee04: "", // 停止喝咖啡年龄
    },
    exerciseList: ["骑车", "跑步", "打太极", "登山", "跳舞", "散步", "其它"],
    teaTypeList: ["红茶", "绿茶", "白茶", "黑茶", "其它"],
    savePastLivingHabits() {
      if (!basicInformationModule.caseId) {
        ins.$message.warning("请先填写基本信息！");
        return;
      } else {
        pastLivingHabitsModule.pastLivingHabitsForm.user_id =
          basicInformationModule.caseId;
      }
      const formRef = ins.$refs.pastLivingHabitsRuleForm as any;
      const param = {
        ...pastLivingHabitsModule.pastLivingHabitsForm,
      };
      ApiPastLivingHabitsSave(param).then((res) => {
        if (res.success) {
          pastLivingHabitsModule.pastLivingHabitsForm.hishabit_id = res.data;
          ins.$message.success("保存成功！");
        }
      });
    },
    pastLivingHabitsRadio(property: string, content: string) {
      if (pastLivingHabitsModule.pastLivingHabitsForm[property] == content) {
        pastLivingHabitsModule.pastLivingHabitsForm[property] = "";
      } else {
        pastLivingHabitsModule.pastLivingHabitsForm[property] = content;
      }
    },
  });
  return {
    pastLivingHabitsModule,
  };
}
export { pastLivingHabitsModuleFun };
