//电子病例-其它治疗史
import { computed, onMounted, reactive, watch } from "@vue/composition-api";
import { ApiOtherTreatmentHistorySave } from "@/apis/index";
interface Module {
  basicInformationForm: object;
}
function otherTreatmentHistoryModuleFun(ins: any, basicInformationModule: any) {
  const otherTreatmentHistoryModule: any = reactive({
    otherTreatmentHistoryForm: {
      data: [
        {
          user_id: null,
          histrmt_id: null,

          histrmt_measure: "", // 治疗措施
          histrmt_date: "", // 治疗年时间
          histrmt_hospital: "", // 治疗医院
          histrmt_description: "", // 治疗描述
          histrmt_note: "", // 备注
        },
      ],
    },
    treatmentMeasuresList: [
      "毁损术",
      "肉毒毒素注射",
      "中药针灸",
      "DBS手术",
      "其它",
    ],
    addOtherTreatmentHistoryFormItem() {
      const formRef = ins.$refs.otherTreatmentHistoryRuleForm as any;
      formRef.validate((valid: boolean) => {
        if (valid) {
          otherTreatmentHistoryModule.otherTreatmentHistoryForm.data.push({
            user_id: null,
            histrmt_id: null,

            histrmt_measure: "", // 治疗措施
            histrmt_date: "", // 治疗年时间
            histrmt_hospital: "", // 治疗医院
            histrmt_description: "", // 治疗描述
            histrmt_note: "", // 备注
          });
        }
      });
    },
    deleteOtherTreatmentHistoryFormItem(index: number) {
      otherTreatmentHistoryModule.otherTreatmentHistoryForm.data.splice(
        index,
        1
      );
    },
    saveOtherTreatmentHistory() {
      //验证基础信息
      if (!basicInformationModule.caseId) {
        ins.$message.warning("请先填写基本信息！");
        return;
      } else {
        otherTreatmentHistoryModule.otherTreatmentHistoryForm.data =
          otherTreatmentHistoryModule.otherTreatmentHistoryForm.data.map(
            (item: any) => {
              item.user_id = basicInformationModule.caseId;
              return item;
            }
          );
      }
      const formRef = ins.$refs.otherTreatmentHistoryRuleForm as any;
      formRef.validate((valid: boolean) => {
        if (valid) {
          const param =
            otherTreatmentHistoryModule.otherTreatmentHistoryForm.data;
          ApiOtherTreatmentHistorySave(param).then((res) => {
            if (res.success) {
              ins.$message.success("保存成功！");
              for (let i = 0; i < res.data.length; i++) {
                otherTreatmentHistoryModule.otherTreatmentHistoryForm.data[
                  i
                ].histrmt_id = res.data[i];
              }
            }
          });
        }
      });
    },
    goBack() {
      ins.$router.go(-1);
    },
  });
  return {
    otherTreatmentHistoryModule,
  };
}
export { otherTreatmentHistoryModuleFun };
