//电子病例-运动并发症
import { computed, onMounted, reactive, watch } from "@vue/composition-api";
import { ApiMovementComplicationsSave } from "@/apis/index";
interface Module {
  basicInformationForm: object;
}
function movementComplicationsModuleFun(ins: any, basicInformationModule: any) {
  const movementComplicationsModule: any = reactive({
    movementComplicationsForm: {
      user_id: null,
      hiscomp_id: null,

      hiscomp_yidong_date: "", // 异动症--出现时间
      hiscomp_yidong03: "", // 异动症--出现症状时服药方案
      hiscomp_yidong04: "", // 异动症--出现症状时等效美多芭剂量
      hiscomp_yidong05: "", // 异动症-与服药的关系
      hiscomp_jimo_date: "", // 剂末现象--出现时间
      hiscomp_jimo03: "", // 剂末现象--出现症状时服药方案
      hiscomp_jimo04: "", // 剂末现象--出现症状时等效美多芭剂量
      hiscomp_tongx_date: "", // 痛性痉挛--出现时间-年份
      hiscomp_tongx03: "", // 痛性痉挛--出现症状时服药方案
      hiscomp_tongx04: "", // 痛性痉挛--出现症状时等效美多芭剂量
      hiscomp_chenj_date: "", // 晨僵--出现时间
      hiscomp_chenj03: "", // 晨僵--出现症状时服药方案
      hiscomp_chenj04: "", // 晨僵--出现症状时等效美多芭剂量
      hiscomp_kaig_date: "", // 开关现象--出现时间
      hiscomp_kaig03: "", // 开关现象--出现症状时服药方案
      hiscomp_kaig04: "", // 开关现象--出现症状时等效美多芭剂量
      hiscomp_kaig05: "", // 开关现象--有不可预测的关
      hiscomp_kaig06: "", // 开关现象--与服药的关系
    },
    saveMovementComplications() {
      if (!basicInformationModule.caseId) {
        ins.$message.warning("请先填写基本信息！");
        return;
      } else {
        movementComplicationsModule.movementComplicationsForm.user_id =
          basicInformationModule.caseId;
      }
      const param = {
        ...movementComplicationsModule.movementComplicationsForm,
      };
      ApiMovementComplicationsSave(param).then((res) => {
        if (res.success) {
          movementComplicationsModule.movementComplicationsForm.hiscomp_id =
            res.data;
          ins.$message.success("保存成功！");
        }
      });
    },
    movementComplicationsRadio(property: string, content: string) {
      if (
        movementComplicationsModule.movementComplicationsForm[property] ==
        content
      ) {
        movementComplicationsModule.movementComplicationsForm[property] = "";
      } else {
        movementComplicationsModule.movementComplicationsForm[property] =
          content;
      }
    },
  });
  return {
    movementComplicationsModule,
  };
}
export { movementComplicationsModuleFun };
