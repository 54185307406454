//电子病例:家族史
import { computed, onMounted, reactive, watch } from "@vue/composition-api";
import { ApiFamilyHistorySave, ApiAllergyHistorySave } from "@/apis/index";
interface Module {
  basicInformationForm: object;
}

function familyHistoryModuleFun(ins: any, basicInformationModule: any) {
  const familyHistoryModule: any = reactive({
    familyHistoryForm: {
      data: [
        {
          user_id: "", // 患者病历id
          hisfam_id: "", // 家族史id

          hisfam_relation: "", // 与患者关系
          hisfam_indate: "", // 发病年龄
          hisfam_name: "", // 家属姓名
          hisfam_diagnosis: "", // 疾病诊断
          hisfam_issimilar: "", // 症状是否类似
          hisfam_isclear: "", // 诊断是否明确
          hisfam_isalive: "", // 家属是否健在
          hisfam_tele: "", // 家属联系方式
          hisfam_hospital: "", // 诊断医院
          hisfam_other: "", // 其它情况说明
        },
      ],
    },
    hisfam_relation: ["父母", "子女", "夫妻", "其它"],
    hisfam_diagnosis: ["未知", "痊愈", "好转", "未变", "有后遗症", "恶化"],
    // hisfam_diagnosis: ["帕金森病", "帕金森综合征", "步态障碍", "肌张力障碍", "痉挛状态", "肌阵挛", "震颤", "舞蹈症", "投掷", "手足徐动", "抽动障碍", "偏侧面肌痉挛", "共济失调(行走不稳)", "发作性运动障碍", "夜间运动障碍", "混合型运动障碍", "其他"],

    addFamilyHistoryFormItem() {
      const formRef = ins.$refs.familyHistoryRuleForm as any;
      formRef.validate((valid: boolean) => {
        if (valid) {
          familyHistoryModule.familyHistoryForm.data.push({
            user_id: "", // 患者病历id
            hisfam_id: "", // 家族史id

            hisfam_relation: "", // 与患者关系
            hisfam_indate: "", // 发病年龄
            hisfam_name: "", // 家属姓名
            hisfam_diagnosis: "", // 疾病诊断
            hisfam_issimilar: "", // 症状是否类似
            hisfam_isclear: "", // 诊断是否明确
            hisfam_isalive: "", // 家属是否健在
            hisfam_tele: "", // 家属联系方式
            hisfam_hospital: "", // 诊断医院
            hisfam_other: "", // 其它情况说明
          });
        }
      });
    },
    deleteFamilyHistoryFormItem(index: number) {
      familyHistoryModule.familyHistoryForm.data.splice(index, 1);
    },
    saveFamilyHistory() {
      //验证基础信息
      if (!basicInformationModule.caseId) {
        ins.$message.warning("请先填写基本信息！");
        return;
      } else {
        familyHistoryModule.familyHistoryForm.data =
          familyHistoryModule.familyHistoryForm.data.map((item: any) => {
            item.user_id = basicInformationModule.caseId;
            return item;
          });
      }
      const formRef = ins.$refs.familyHistoryRuleForm as any;
      formRef.validate((valid: boolean) => {
        if (valid) {
          const param = familyHistoryModule.familyHistoryForm.data;
          ApiFamilyHistorySave(param).then((res) => {
            if (res.success) {
              ins.$message.success("保存成功！");
              for (let i = 0; i < res.data.length; i++) {
                familyHistoryModule.familyHistoryForm.data[i].hisfam_id =
                  res.data[i];
              }
            }
          });
        }
      });
    },
    familyHistoryRadio(property: string, content: string, index: number) {
      if (
        familyHistoryModule.familyHistoryForm.data[index][property] == content
      ) {
        familyHistoryModule.familyHistoryForm.data[index][property] = "";
      } else {
        familyHistoryModule.familyHistoryForm.data[index][property] = content;
      }
    },
  });
  return {
    familyHistoryModule,
  };
}

export { familyHistoryModuleFun };
