//电子病例:症状诊断、过敏史
import { computed, onMounted, reactive, watch } from "@vue/composition-api";
import { ApiSymptomDiagnosisSave, ApiAllergyHistorySave } from "@/apis/index";
interface Module {
  basicInformationForm: object;
}

function symptomDiagnosisModuleFun(
  ins: any,
  basicInformationModule: any,
  chiefComplaintModule: any
) {
  const symptomDiagnosisModule: any = reactive({
    symptomDiagnosisForm: {
      data: [
        {
          user_id: "",
          trmtchief_id: "",
          chatidiag_id: null,

          chatidiag_doctor: "",
          chatidiag_type: "",
          chatidiag_second: "",
          chatidiag_third: "",
          chatidiag_date: "",
          chatidiag_isclear: "",
          chatidiag_sign1: "",
          chatidiag_sign2: "",
          chatidiag_sign3: "",
          chatidiag_sign4: "",
          chatidiag_sign5: "",
          chatidiag_sign6: "",
          chatidiag_sign7: "",
          chatidiag_note: "",
        },
      ],
    },
    diagnosisType: [
      "原发性帕金森病",
      "帕金森叠加综合征",
      "继发性帕金森综合征",
      "遗传性帕金森综合征",
      "原发性震颤",
      "其它",
    ],
    // 二级诊断
    diagnose1: [
      "⽆体征",
      "1.0=单侧患病",
      "1.5=单侧患病,并影响到中轴的肌⾁",

      "2.0=双侧患病,为损害平衡",

      "2.5=轻度双侧患病,姿势反射稍差,但是能自己纠正",

      "3.0=双侧患病,有姿态平衡障碍,后拉试验阳性",

      "4.0=严重的残疾,但是能⾃⼰站⽴或者⾏⾛",

      "5.0=不能起床,或⽣活在轮椅上",

      "其它",
    ],
    diagnose2: [
      "进⾏性核上性⿇痹",
      "MSA-C",
      "MSA-P",
      "⽪质基底节变性",
      "Shy-Darger综合征",
      "其它",
    ],
    diagnose3: [
      "脑外伤后",
      "脑⾎管",
      "脑炎后",
      "毒物性",
      "药物性",
      "代谢性",
      "其它",
    ],
    secondaryDiagnosis(item: any) {
      let result = [];
      if (item.chatidiag_type == "原发性帕金森病") {
        result = symptomDiagnosisModule.diagnose1;
      }
      if (item.chatidiag_type == "帕金森叠加综合征") {
        result = symptomDiagnosisModule.diagnose2;
      }
      if (item.chatidiag_type == "继发性帕金森综合征") {
        result = symptomDiagnosisModule.diagnose3;
      }
      return result;
    },
    symptomDiagnosisSelectChange(index: number) {
      symptomDiagnosisModule.symptomDiagnosisForm.data[index].chatidiag_second =
        "";
    },
    addSymptomDiagnosisFormItem() {
      const formRef = ins.$refs.symptomDiagnosisRuleForm as any;
      formRef.validate((valid: boolean) => {
        if (valid) {
          symptomDiagnosisModule.symptomDiagnosisForm.data.push({
            user_id: "",
            trmtchief_id: "",
            chatidiag_id: null,

            chatidiag_doctor: "",
            chatidiag_type: "",
            chatidiag_second: "",
            chatidiag_third: "",
            chatidiag_date: "",
            chatidiag_isclear: "",
            chatidiag_sign1: "",
            chatidiag_sign2: "",
            chatidiag_sign3: "",
            chatidiag_sign4: "",
            chatidiag_sign5: "",
            chatidiag_sign6: "",
            chatidiag_sign7: "",
            chatidiag_note: "",
          });
        }
      });
    },
    deleteSymptomDiagnosisFormItem(index: number) {
      symptomDiagnosisModule.symptomDiagnosisForm.data.splice(index, 1);
    },
    saveSymptomDiagnosis() {
      //验证基础信息
      if (!basicInformationModule.caseId) {
        ins.$message.warning("请先填写基本信息！");
        return;
      } else {
        symptomDiagnosisModule.symptomDiagnosisForm.data =
          symptomDiagnosisModule.symptomDiagnosisForm.data.map((item: any) => {
            item.user_id = basicInformationModule.caseId;
            return item;
          });
      }
      //验证主诉
      // if (!chiefComplaintModule.chiefComplaintId) {
      //   ins.$message.warning("请先填写门诊病历！");
      //   return;
      // } else {
      //   symptomDiagnosisModule.symptomDiagnosisForm.data =
      //     symptomDiagnosisModule.symptomDiagnosisForm.data.map((item: any) => {
      //       item.trmtchief_id = chiefComplaintModule.chiefComplaintId;
      //       return item;
      //     });
      // }
      const formRef = ins.$refs.symptomDiagnosisRuleForm as any;
      formRef.validate((valid: boolean) => {
        if (valid) {
          const param = symptomDiagnosisModule.symptomDiagnosisForm.data;
          ApiSymptomDiagnosisSave(param).then((res) => {
            if (res.success) {
              ins.$message.success("保存成功！");
              for (let i = 0; i < res.data.length; i++) {
                symptomDiagnosisModule.symptomDiagnosisForm.data[
                  i
                ].chatidiag_id = res.data[i];
              }
            }
          });
        }
      });
    },
    symptomDiagnosisRadio(property: string, content: string, index: number) {
      if (
        symptomDiagnosisModule.symptomDiagnosisForm.data[index][property] ==
        content
      ) {
        symptomDiagnosisModule.symptomDiagnosisForm.data[index][property] = "";
      } else {
        symptomDiagnosisModule.symptomDiagnosisForm.data[index][property] =
          content;
      }
    },
    //过敏史
    allergyHistoryForm: {
      user_id: "",
      allergies: "",
    },
    saveAllergyHistory() {
      //验证基础信息
      if (!basicInformationModule.caseId) {
        ins.$message.warning("请先填写基本信息！");
        return;
      } else {
        symptomDiagnosisModule.allergyHistoryForm.user_id =
          basicInformationModule.caseId;
      }
      const param = {
        ...symptomDiagnosisModule.allergyHistoryForm,
      };
      ApiAllergyHistorySave(param).then((res) => {
        if (res.success) {
          ins.$message.success("保存成功！");
        }
      });
    },
  });
  return {
    symptomDiagnosisModule,
  };
}

export { symptomDiagnosisModuleFun };
