//电子病例:一般用药史
import { computed, onMounted, reactive, watch } from "@vue/composition-api";
import {
  ApiGeneralMedicationHistorySave,
  ApiAllergyHistorySave,
} from "@/apis/index";
interface Module {
  basicInformationForm: object;
}

function generalMedicationHistoryModuleFun(
  ins: any,
  basicInformationModule: any
) {
  const generalMedicationHistoryModule: any = reactive({
    generalMedicationHistoryForm: {
      data: [
        {
          user_id: "", // 患者病历id
          hisfam_id: "", // 家族史id

          hismed_type: "", //类别
          hismed_name: "", //药物名称
          hismed_indate: "", //开始服药时间
          hismed_freq: "", //日均总剂量
          hismed_dose: "", //服药方案
          hismed_description: "", //服药说明
          hismed_isadverse: "", //有无不良反应
          hismed_iseffect: "", //是否有效
          hismed_adversecnt: "", //不良反应内容
          hismed_isgoon: "", //是否继续服用
          hismed_offdate: "", //停药时间
          hismed_offreason: "", //停药原因
          hismed_note: "", //备注
        },
      ],
    },
    //药名
    drugName: [
      "多巴丝肼",
      "普拉克索",
      "吡贝地尔",
      "雷沙吉兰",
      "司来吉兰",
      "沙芬酰胺",
      "金刚烷胺",
      "盐酸苯海索",
      "溴隐亭",
      "卡左双多巴",
      "恩他卡朋",
      "托卡朋",
      "奥匹卡朋",
      "罗替高汀",
      "罗匹尼罗",
      "斯达力沃/达灵复",
      "麦角乙脲",
      "培高利特",
      "氟桂利嗪",
      "桂利嗪",
      "利血平",
      "复方降压0号",
      "黛力新",
      "氟哌啶醇",
      "维思通",
      "奋乃静",
      "舒必利",
      "安律凡",
      "胺碘酮",
      "氟西汀",
      "帕罗西汀",
      "文拉法辛",
      "舍曲林",
      "奥氮平",
      "喹硫平",
      "其它",
    ],
    //服药说明
    specification: [
      "餐前半小时",
      "餐前一小时",
      "餐后半小时",
      "餐后两小时",
      "其它",
    ],
    //不良反应内容
    adverseReaction: [
      "无",
      "恶心",
      "呕吐",
      "头晕",
      "幻觉",
      "智力下降",
      "运动并发症",
      "其它",
    ],
    // 停药原因
    stopDrug: ["无", "不良反应", "药效不佳", "费用", "药物撤市", "其它"],

    addGeneralMedicationHistoryFormItem() {
      const formRef = ins.$refs.generalMedicationHistoryRuleForm as any;
      formRef.validate((valid: boolean) => {
        if (valid) {
          generalMedicationHistoryModule.generalMedicationHistoryForm.data.push(
            {
              user_id: "", // 患者病历id
              hisfam_id: "", // 家族史id

              hismed_type: "", //类别
              hismed_name: "", //药物名称
              hismed_indate: "", //开始服药时间
              hismed_freq: "", //日均总剂量
              hismed_dose: "", //服药方案
              hismed_description: "", //服药说明
              hismed_isadverse: "", //有无不良反应
              hismed_iseffect: "", //是否有效
              hismed_adversecnt: "", //不良反应内容
              hismed_isgoon: "", //是否继续服用
              hismed_offdate: "", //停药时间
              hismed_offreason: "", //停药原因
              hismed_note: "", //备注
            }
          );
        }
      });
    },
    deleteGeneralMedicationHistoryFormItem(index: number) {
      generalMedicationHistoryModule.generalMedicationHistoryForm.data.splice(
        index,
        1
      );
    },
    saveGeneralMedicationHistory() {
      //验证基础信息
      if (!basicInformationModule.caseId) {
        ins.$message.warning("请先填写基本信息！");
        return;
      } else {
        generalMedicationHistoryModule.generalMedicationHistoryForm.data =
          generalMedicationHistoryModule.generalMedicationHistoryForm.data.map(
            (item: any) => {
              item.user_id = basicInformationModule.caseId;
              return item;
            }
          );
      }
      const formRef = ins.$refs.generalMedicationHistoryRuleForm as any;
      formRef.validate((valid: boolean) => {
        if (valid) {
          const param =
            generalMedicationHistoryModule.generalMedicationHistoryForm.data;
          ApiGeneralMedicationHistorySave(param).then((res) => {
            if (res.success) {
              ins.$message.success("保存成功！");
              for (let i = 0; i < res.data.length; i++) {
                generalMedicationHistoryModule.generalMedicationHistoryForm.data[
                  i
                ].hisfam_id = res.data[i];
              }
            }
          });
        }
      });
    },
    generalMedicationRadio(property: string, content: string, index: number) {
      if (
        generalMedicationHistoryModule.generalMedicationHistoryForm.data[index][
          property
        ] == content
      ) {
        generalMedicationHistoryModule.generalMedicationHistoryForm.data[index][
          property
        ] = "";
      } else {
        generalMedicationHistoryModule.generalMedicationHistoryForm.data[index][
          property
        ] = content;
      }
    },
  });
  return {
    generalMedicationHistoryModule,
  };
}

export { generalMedicationHistoryModuleFun };
