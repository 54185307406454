//电子病例：既往环境接触史
import { computed, onMounted, reactive, watch } from "@vue/composition-api";
import { ApiEnvironmentalExposureSave } from "@/apis/index";
interface Module {
  basicInformationForm: object;
}
function environmentalExposureModuleFun(ins: any, basicInformationModule: any) {
  const environmentalExposureModule: any = reactive({
    environmentalExposureForm: {
      user_id: null,
      hisenviro_id: null,

      hisenviro_occupation: "", // 从事时间最长职业
      hisenviro_occuyears: "", // 从事时间最长职业时长--年
      hisenviro_occu01years: "", // 从事时间最长职业时长--月
      hisenviro_occu03: "", // 是否从事化学溶剂工作
      hisenviro_occu01: "", //是否从事需要灭害剂（包括：除草剂、杀虫剂、灭菌剂、熏蒸剂等）的工作
      hisenviro_occu05: "", // 是否从事其它化学制剂或者气雾剂
      hisenviro_occu04: "", // 是否从事接触重金属的工作
      hisenviro_occu02: "", // 是否曾经在家里接触了灭害剂
      hisenviro_occu06: "", // 是否从事电焊工作
    },
    occupationList: ["工人", "教师", "农民", "干部", "学生", "职员", "其它"],
    saveEnvironmentalExposure() {
      if (!basicInformationModule.caseId) {
        ins.$message.warning("请先填写基本信息！");
        return;
      } else {
        environmentalExposureModule.environmentalExposureForm.user_id =
          basicInformationModule.caseId;
      }
      const formRef = ins.$refs.environmentalExposureRuleForm as any;
      const param = {
        ...environmentalExposureModule.environmentalExposureForm,
      };
      ApiEnvironmentalExposureSave(param).then((res) => {
        if (res.success) {
          environmentalExposureModule.environmentalExposureForm.hisenviro_id =
            res.data;
          ins.$message.success("保存成功！");
        }
      });
    },
    environmentalRadio(property: string, content: string) {
      if (
        environmentalExposureModule.environmentalExposureForm[property] ==
        content
      ) {
        environmentalExposureModule.environmentalExposureForm[property] = "";
      } else {
        environmentalExposureModule.environmentalExposureForm[property] =
          content;
      }
    },
  });
  return {
    environmentalExposureModule,
  };
}
export { environmentalExposureModuleFun };
