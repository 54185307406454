//病例新增页面-滚动模块
import { computed, reactive, watch } from "@vue/composition-api";
interface Module {
  currentTreeNode: string;
}
function scrollModuleFun(ins: any) {
  const scrollModule: any = reactive({
    //当前选中节点
    currentTreeNode: "#Bases",
    //最大滚动高度
    maximumScrollHeight: 0,
    //菜单列表
    treeList: [
      {
        label: "基础信息",
        id: "#Bases",
        showState: true,
        isCompleted: false,
      },
      {
        label: "门诊病历",
        id: "#Subject",
        showState: true,
        isCompleted: false,
      },
      {
        label: "现病史",
        id: "#Medicals",
        showState: false,
        isCompleted: false,
        children: [
          {
            label: "起病情况",
            id: "#Medical",
            showState: true,
            isCompleted: false,
          },
          {
            label: "进展顺序",
            id: "#Progress",
            showState: true,
            isCompleted: false,
          },
          {
            label: "主要症状",
            id: "#Cardinal",
            showState: true,
            isCompleted: false,
          },
          {
            label: "其它运动症状",
            id: "#Rest",
            showState: true,
            isCompleted: false,
          },
        ],
      },
      {
        label: "症状诊断",
        id: "#Diagnostic",
        showState: true,
        isCompleted: false,
      },
      {
        label: "既往史",
        id: "#Previouss",
        showState: false,
        isCompleted: false,
        children: [
          {
            label: "既往疾病史",
            id: "#Previous",
            showState: true,
            isCompleted: false,
          },
          {
            label: "既往环境接触史",
            id: "#ContactHistory",
            showState: true,
            isCompleted: false,
          },
          {
            label: "既往生活习惯",
            id: "#Living",
            showState: true,
            isCompleted: false,
          },
        ],
      },
      {
        label: "过敏史",
        id: "#Allergy",
        showState: true,
        isCompleted: false,
      },
      {
        label: "家族史",
        id: "#Family",
        showState: true,
        isCompleted: false,
      },
      {
        label: "用药史",
        id: "#Medications",
        showState: false,
        isCompleted: false,
        children: [
          {
            label: "一般用药史",
            id: "#Medication",
            showState: true,
            isCompleted: false,
          },
          {
            label: "运动并发症",
            id: "#MotorComplications",
            showState: true,
            isCompleted: false,
          },
        ],
      },
      {
        label: "其它治疗史",
        id: "#ElseTreatment",
        showState: true,
        isCompleted: false,
      },
      {
        label: "流行病学史",
        id: "#EpidemiologicalHistory",
        showState: true,
        isCompleted: false,
      },
    ],
    //菜单对应的元素列表
    moduleList: [
      //流行病学史
      {
        id: "#EpidemiologicalHistory",
        top: Infinity,
        height: Infinity,
      },
      //其他治疗史
      {
        id: "#ElseTreatment",
        top: Infinity,
        height: Infinity,
      },
      //运动并发症
      {
        id: "#MotorComplications",
        top: Infinity,
        height: Infinity,
      },
      //一般用药史
      {
        id: "#Medication",
        top: Infinity,
        height: Infinity,
      },
      //家族史
      {
        id: "#Family",
        top: Infinity,
        height: Infinity,
      },
      //过敏史
      {
        id: "#Allergy",
        top: Infinity,
        height: Infinity,
      },
      //既往生活习惯
      {
        id: "#Living",
        top: Infinity,
        height: Infinity,
      },
      //既往环境接触史
      {
        id: "#ContactHistory",
        top: Infinity,
        height: Infinity,
      },
      //既往疾病史
      {
        id: "#Previous",
        top: Infinity,
        height: Infinity,
      },
      //症状诊断
      {
        id: "#Diagnostic",
        top: Infinity,
        height: Infinity,
      },
      //其他运动症状
      {
        id: "#Rest",
        top: Infinity,
        height: Infinity,
      },
      //主要症状
      {
        id: "#Cardinal",
        top: Infinity,
        height: Infinity,
      },
      //进展顺序
      {
        id: "#Progress",
        top: Infinity,
        height: Infinity,
      },
      //起病情况
      {
        id: "#Medical",
        top: Infinity,
        height: Infinity,
      },
      //问诊病历
      {
        id: "#Subject",
        top: Infinity,
        height: Infinity,
      },
      //基础信息
      {
        id: "#Bases",
        top: Infinity,
        height: Infinity,
      },
    ],
    //防抖用的计时器变量
    timer: null,
    preShake(fn: any, timeout: any) {
      return function () {
        if (scrollModule.timer) {
          clearTimeout(scrollModule.timer);
        }
        scrollModule.timer = setTimeout(fn, timeout);
      };
    },
    handleScroll() {
      scrollModule.preShake(scrollModule.showTop, 500)();
    },
    updateScroll() {
      const contentDom: any = document.getElementById("list");

      //初始化最大滚动高度
      const lastModuleEl: any = document.getElementById("ElseTreatment");
      scrollModule.maximumScrollHeight =
        lastModuleEl.offsetTop +
        lastModuleEl.offsetHeight -
        contentDom.clientHeight -
        56;
      //初始化元素列表中元素高度
      const moduleList = scrollModule.moduleList;
      moduleList.forEach((item: any, index: number) => {
        scrollModule.moduleList[index].top =
          document.querySelector(item.id).offsetTop - 150;
      });
      console.log(scrollModule.moduleList);
    },
    //直接改变主体内容滚动高度，会触发监听的滚动事件
    //使用元素实例的scrollIntoView方法依然会触发监听的滚动事件
    showTop() {
      scrollModule.updateScroll();
      //获取当前滚动高度
      const contentDom: any = document.getElementById("list");
      const currentTop = contentDom.scrollTop;
      // console.log("当前滚动高度值：" + currentTop);

      const moduleList = scrollModule.moduleList;
      //150为判断滚动到顶部（基础信息）的边界值
      //如果滚动高度小于150，则设定菜单为基础信息
      if (currentTop < 150) {
        (ins.$refs.treeRef as any).setCurrentKey(
          moduleList[moduleList.length - 1].id
        );
        return;
      }
      //用于判断滚动到底部（其他治疗史）的边界值
      //为最大滚动高度-150 ，如果滚动高度大于此边界，则设定菜单为其他治疗史
      const criticalValue = scrollModule.maximumScrollHeight - 150;
      if (currentTop > criticalValue || currentTop == criticalValue) {
        (ins.$refs.treeRef as any).setCurrentKey(moduleList[0].id);
        return;
      }
      //对除了基础信息和其他治疗史的模块，进行高度判断
      for (let i = 0; i < moduleList.length; i++) {
        const moduleHeight = moduleList[i].top;
        if (currentTop > moduleHeight || currentTop == moduleHeight) {
          (ins.$refs.treeRef as any).setCurrentKey(moduleList[i].id);
          //对菜单元素失焦处理
          scrollModule.outFocus();
          return;
        }
      }
    },
    outFocus() {
      const input = document.createElement("input");
      document.body.prepend(input);
      input.setAttribute("style", "position:absolute;top:0px;left:0px;");
      input.focus();
      input.remove();
    },
    clickNode(data: any, node: any, self: any) {
      const neglectList = new Set(["#Medicals", "#Previouss", "#Medications"]);
      if (neglectList.has(data.id)) {
        return;
      }
      scrollModule.updateScroll();
      const contentDom: any = document.getElementById("list");
      const currentModuleEl = document.querySelector(data.id);
      const currentModuleElHeight = scrollModule.moduleList.find(
        (listItem: any) => {
          return listItem.id == data.id;
        }
      ).top;
      // console.log(currentModuleElHeight);
      //直接改变主体内容滚动高度，会触发监听的滚动事件
      //使用元素实例的scrollIntoView方法依然会触发监听的滚动事件
      // console.log(
      //   "当前点击菜单对应元素判断高度值:" + currentModuleEl.offsetTop
      // );
      if (currentModuleEl.offsetTop > scrollModule.maximumScrollHeight) {
        contentDom.scrollTop = scrollModule.maximumScrollHeight;
      } else {
        // console.log(currentModuleElHeight);
        contentDom.scrollTop = currentModuleElHeight + 1;
        // console.log(contentDom.scrollTop);
      }
    },
  });
  return {
    scrollModule,
  };
}
export { scrollModuleFun };
