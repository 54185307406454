//电子病例:既往疾病史
import { computed, onMounted, reactive, watch } from "@vue/composition-api";
import { ApiPastMedicalHistorySave } from "@/apis/index";
interface Module {
  basicInformationForm: object;
}

function pastMedicalHistoryModuleFun(ins: any, basicInformationModule: any) {
  const pastMedicalHistoryModule: any = reactive({
    pastMedicalHistoryForm: {
      data: [
        {
          user_id: null,
          hisdisease_id: null,

          hisdisease_name: "", //疾病名称
          hisdisease_date: "", //发病时间
          hisdisease_outcome: "", //转归
          hisdisease_pharmacy: "", //用药
          hisdisease_note: "", //备注
        },
      ],
    },
    hisdiseaseNameList: [
      "高血压",
      "糖尿病",
      "心脏病",
      "甲亢",
      "麻疹",
      "脑卒中",
      "脑外伤",
      "脑炎",
      "癫痫",
      "一氧化碳(煤气)中毒",
      "其它",
    ],
    fateList: ["未知", "痊愈", "好转", "未变", "有后遗症", "恶化"],
    addPastMedicalHistoryFormItem() {
      const formRef = ins.$refs.pastMedicalHistoryRuleForm as any;
      formRef.validate((valid: boolean) => {
        if (valid) {
          pastMedicalHistoryModule.pastMedicalHistoryForm.data.push({
            user_id: null,
            hisdisease_id: null,

            hisdisease_name: "", //疾病名称
            hisdisease_date: "", //发病时间
            hisdisease_outcome: "", //转归
            hisdisease_pharmacy: "", //用药
            hisdisease_note: "", //备注
          });
        }
      });
    },
    deletePastMedicalHistoryFormItem(index: number) {
      pastMedicalHistoryModule.pastMedicalHistoryForm.data.splice(index, 1);
    },
    savePastMedicalHistory() {
      //验证基础信息
      if (!basicInformationModule.caseId) {
        ins.$message.warning("请先填写基本信息！");
        return;
      } else {
        pastMedicalHistoryModule.pastMedicalHistoryForm.data =
          pastMedicalHistoryModule.pastMedicalHistoryForm.data.map(
            (item: any) => {
              item.user_id = basicInformationModule.caseId;
              return item;
            }
          );
      }
      const formRef = ins.$refs.pastMedicalHistoryRuleForm as any;
      formRef.validate((valid: boolean) => {
        if (valid) {
          const param = pastMedicalHistoryModule.pastMedicalHistoryForm.data;
          ApiPastMedicalHistorySave(param).then((res) => {
            if (res.success) {
              ins.$message.success("保存成功！");
              for (let i = 0; i < res.data.length; i++) {
                pastMedicalHistoryModule.pastMedicalHistoryForm.data[
                  i
                ].hisdisease_id = res.data[i];
              }
            }
          });
        }
      });
    },
  });
  return {
    pastMedicalHistoryModule,
  };
}

export { pastMedicalHistoryModuleFun };
